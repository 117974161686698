"use client";

import { datadogRum } from "@datadog/browser-rum";

// Check if the environment is production
if (process.env.NEXT_PUBLIC_ENV === "prod") {
  datadogRum.init({
    applicationId: "f4d7ee0e-dadf-43b3-8308-8ebfc62dfb14",
    clientToken: "pub1a2e9affd32437ae94d68eca82d7d398",
    site: "us5.datadoghq.com",
    service: "online-ordering",
    env: process.env.NEXT_PUBLIC_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}

export default function DatadogInit() {
  return null;
}
